@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('satoshi.css');
@import url('minimal-mono.css');

body {
  margin: 0;
  /* padding: 8rem 5% 8rem 5%; */
  font-family: 'Satoshi-Regular', sans-serif;
  background-color: #faebd7;
  /* background-color: #f0e9e1; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::selection {
  background-color: #ddbd9280;
  /* background-color: #c6c3e87a; */
  /* color: #4e310b; */
}